import styled, { css } from 'styled-components'
import TimesIcon from '../../../images/icon_times_black.svg'
import { RegisterContentWrapperCss } from '../registerContent/styles'
import { themeStyles, themeMedia } from '../../../styles/theme'
import { StyledH2, StyledH3, StyledParagraph } from '../../atoms/text/styles'

export const StyledRegisterModalWrapper = styled.div`
  position: relative;
  overflow: auto;
`

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 0.8em;
  right: 0.8em;
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  padding: 0;
  background-size: 0.7em 0.7em;
  background-color: transparent;
  background-image: url('${TimesIcon}');
  background-position: center;
  background-repeat: no-repeat;

  ${themeMedia.maxTablet`
    display: none;
  `}
`

export const StyledLeavingContent = styled.div`
  ${RegisterContentWrapperCss}
`

export const StyledLeavingContentMobile = styled.div`
  ${RegisterContentWrapperCss}
  flex-direction: column;
  gap: 1.1em;

  ${StyledH2}, ${StyledH3} {
    font-weight: 700;
  }

  ${StyledParagraph} {
    margin-top: 0.2em;
    margin-bottom: 0;
    padding: 0;
  }
`

export const StyledLeavingText = styled.div`
  display: flex;
  padding-top: 3em;

  ${StyledParagraph} {
    margin-top: 1em;
  }
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4.5em;

  ${themeMedia.maxDesktop`
    margin-top: 1.8em;
  `}
`

export const StyledCancelLink = styled.button`
  color: ${themeStyles.colors.black.main};
  text-decoration: underline;
  background-color: transparent;
  margin: 1em auto;
  padding: 1em;

  ${themeMedia.maxTablet`
    margin-top: 0;
    padding: 0;
  `}
`

export const StyledAnimatedWrapper = styled.div`
  /* transition: 1s; */
  max-height: 800px;

  ${(props) =>
    props.hide &&
    css`
      overflow: hidden;
      max-height: 0px;
    `}
`
