import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactInputMask from 'react-input-mask'
import { StyledInput, StyledInputWrapper, StyledSuffixWrapper } from './styles'
import { If } from '../if'

const FieldInputArea = (props) => {
  const {
    type,
    step,
    placeholder,
    defaultValue,
    autoComplete: autoComp,
    onFocus,
    onClick,
    onDone,
    disabled,
    valid,
    invalid,
    max,
    min,
    maxLength,
    minLength,
    hideSymbols,
    formProperties,
    formatChars,
    renderCustomInput,
    inputSuffix,
    forceValue,
    suffixPointerEvents,
    mask,
    inputmode,
  } = props
  const { onChange, onBlur, onKeyDown, ref, name, clearErrors } = formProperties

  const [hasRightLength, setHasRightLength] = useState(null)

  function handleAutoComplete() {
    if (autoComp == null) return null
    if (typeof autoComp == 'boolean') return autoComp ? 'on' : 'off'
    return autoComp
  }

  const autoComplete = handleAutoComplete()

  const handleIsValid = (() => {
    if (!minLength) {
      return {
        valid,
        invalid,
      }
    }

    if (hasRightLength === null) {
      return {
        valid: null,
        invalid: null,
      }
    }

    return {
      valid: hasRightLength,
      invalid: !hasRightLength,
    }
  })()

  const handleInputSizeRange = (e) => {
    if (!minLength) return
    const fieldLength = e?.target?.value?.length

    if (fieldLength === 0) {
      setHasRightLength(null)
      return
    }

    if (fieldLength < minLength) {
      setHasRightLength(false)
    }
    if (fieldLength >= minLength) {
      setHasRightLength(true)
    }
  }

  const onChangeCombined = (event) => {
    // Clear errors when user starts typing
    if (clearErrors) clearErrors(name)
    handleInputSizeRange(event)
    if (onChange) onChange(event)
    if (props.onChange) props.onChange(event)
  }

  const onBlurCombined = (e) => {
    handleInputSizeRange(e)
    if (onBlur) onBlur(e)
    if (props.onBlur) props.onBlur(e)
  }

  const onKeyDownCombined = (e) => {
    handleInputSizeRange(e)
    if (onKeyDown) onKeyDown(e)
    if (props.onKeyDown) props.onKeyDown(e)
    if (e.keyCode === 13) onDone()
  }

  const forcedValue = forceValue ? { value: forceValue } : {}

  return (
    <StyledInputWrapper
      valid={handleIsValid.valid}
      invalid={handleIsValid.invalid}
      disabled={disabled}
      hasSuffix={!!inputSuffix}
      hideSymbols={hideSymbols}
    >
      <If
        condition={!!renderCustomInput}
        render={() => renderCustomInput()}
        renderElse={() => (
          <If
            condition={!!mask}
            render={() => (
              <ReactInputMask
                id={name}
                name={name}
                type={type}
                mask={mask}
                maskChar=""
                formatChars={formatChars}
                defaultValue={defaultValue}
                autoComplete={autoComplete}
                maxLength={maxLength}
                disabled={disabled}
                onChange={onChangeCombined}
                onFocus={onFocus}
                onClick={onClick}
                onBlur={onBlurCombined}
                onKeyDown={onKeyDownCombined}
                valid={valid}
                invalid={invalid}
                hideSymbols={hideSymbols}
                placeholder={placeholder ?? ' '} // An empty string is used to detect if there is text in input with css
                hasSuffix={!!inputSuffix}
                {...forcedValue}
              >
                {(inputProps) => (
                  <StyledInput
                    {...inputProps}
                    ref={ref}
                    onChange={inputProps?.onChange}
                    disabled={disabled}
                  />
                )}
              </ReactInputMask>
            )}
            renderElse={() => (
              <StyledInput
                id={name}
                name={name}
                type={type}
                step={step}
                defaultValue={defaultValue}
                autoComplete={autoComplete}
                max={max}
                min={min}
                maxLength={maxLength}
                disabled={disabled}
                onChange={onChangeCombined}
                onClick={onClick}
                onFocus={onFocus}
                onBlur={onBlurCombined}
                onKeyDown={onKeyDownCombined}
                valid={valid}
                invalid={invalid}
                hideSymbols={hideSymbols}
                ref={ref}
                placeholder={placeholder ?? ' '} // An empty string is used to detect if there is text in input with css
                hasSuffix={!!inputSuffix}
                inputmode={inputmode}
                {...forcedValue}
              />
            )}
          />
        )}
      />

      <If
        condition={!!inputSuffix}
        render={() => (
          <StyledSuffixWrapper suffixPointerEvents={suffixPointerEvents}>
            {inputSuffix}
          </StyledSuffixWrapper>
        )}
      />
    </StyledInputWrapper>
  )
}

FieldInputArea.defaultProps = {
  type: 'text',
  step: null,
  placeholder: null,
  defaultValue: null,
  autoComplete: null,
  disabled: false,
  valid: false,
  invalid: false,
  hideSymbols: false,
  max: null,
  min: null,
  maxLength: null,
  minLength: null,
  formProperties: {},
  formatChars: null,
  onChange: () => {},
  onFocus: () => {},
  onClick: () => {},
  onBlur: () => {},
  onKeyDown: () => {},
  onDone: () => {},
  renderCustomInput: null,
  inputSuffix: null,
  suffixPointerEvents: 'none',
  mask: null,
  inputmode: null,
}

FieldInputArea.propTypes = {
  type: PropTypes.string,
  step: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.any,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  hideSymbols: PropTypes.bool,
  max: PropTypes.string,
  min: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formProperties: PropTypes.object,
  formatChars: PropTypes.object,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyDown: PropTypes.func,
  onDone: PropTypes.func,
  renderCustomInput: PropTypes.func,
  inputSuffix: PropTypes.element,
  suffixPointerEvents: PropTypes.string,
  mask: PropTypes.string,
  inputmode: PropTypes.string,
}

export { FieldInputArea }
