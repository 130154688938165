import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'

const modalSize = 'min(730px, 90vh)'

export const RegisterContentWrapperCss = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${themeStyles.colors.white.main};
  min-height: max-content;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0.5em 1.2em;
  gap: 0.5em;

  ${(props) =>
    props.addExtraPadding &&
    css`
      padding-top: 1.4em;
    `}

  ${themeMedia.tablet`
    gap: 2em;
    padding: 2em 0em 0 2em;
    flex-direction: row;
    align-items: center;
    max-height: ${modalSize};

    > * {
      padding-bottom: 2em;
      height: 80vh;
      max-height: calc(${modalSize} - 4em);
      width: 500px !important;
      overflow: visible;
    }
    > *:last-child {
      overflow: auto;
      padding-right: 2.7em;
    }
  `}

  ${themeMedia.desktop`
    gap: 4em;
    padding: 3em 0em 0 3em;

    > * {
      max-height: calc(${modalSize} - 6em);
    }
  `}

  > *:last-child {
    display: flex;
    flex-direction: column;
    gap: 1.4em;
  }
`

export const StyledContent = styled.div`
  ${RegisterContentWrapperCss}
`

export const StyledSectionHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 0.8rem 0;

  ${themeMedia.maxTablet`
    font-size: 1.2em;
  `}
`

export const StyledRow = styled.div`
  display: flex;
  align-items: start;
  gap: 20px;
`

export const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 ${(props) => props.width || '50%'};
  justify-content: end;

  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    &:not(:last-child) {
      margin-right: 5%;
    }
  }
`

export const StyledEmail = styled.div`
  font-weight: 600;
  margin: 1rem 0;
`

export const StyledTandCBox = styled.div`
  max-height: 0;
  overflow-y: hidden;
  transition: 0.2s;
  padding: 0;
  margin-bottom: 2rem;

  ${({ showMore }) =>
    showMore &&
    css`
      padding: 5px 0;
      max-height: min-content;
      overflow-y: auto;
    `}
`

export const StyledShowMoreTandCBtn = styled.div`
  cursor: pointer;
  text-align: center;
  margin-bottom: 1rem;

  span {
    background: ${themeStyles.colors.lightGreys[1].main};
    padding: 0.5rem 1rem;
    border-radius: 20px;
  }
`

export const StyledArrowDown = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 5px;
  filter: invert(1);
  transition: 0.2s;

  ${({ showMore }) =>
    showMore &&
    css`
      transform: rotate(180deg);
    `}
`

export const StyledFirstSection = styled.section`
  display: flex;
  flex-direction: column;

  button {
    margin: 0.6em 0 1.5em;
  }

  a {
    color: ${themeStyles.colors.accentColors.primary.main};
  }
`

export const StyledSecondSection = styled.section`
  button:first-of-type {
    margin: 1.15em 0;
  }

  select {
    background-color: ${themeStyles.colors.white.main};
  }
`

export const StyledThirdSection = styled.section`
  button {
    margin: 0.6em 0 1.5em;
  }
`

export const StyledRecaptchaWrapper = styled.div`
  > * {
    margin: 0.5em auto;
    width: 80%;
  }
`

export const StyledErrorMessageWrapper = styled.div`
  margin: -1.5em 0 0.5em;
`
