import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import Checkmark from '../../../images/icon_tick.svg'

const gapSize = '50px'
const circleSize = '30px'
const checkmarkSize = '22px'

export const StyledOuterWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

export const StyledStepsWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: ${gapSize};
`

export const StyledProgressBarStep = styled.span`
  width: ${circleSize};
  height: ${circleSize};
  line-height: calc(${circleSize} - 0.5em);
  position: relative;
  border-radius: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  background-color: ${themeStyles.colors.lightGreys[1].main};
  border: 3px solid ${themeStyles.colors.lightGreys[1].main};
  z-index: 1;
  box-sizing: border-box;

  ${(props) =>
    props.isDone &&
    css`
      background-color: ${themeStyles.colors.white.main};
      border-color: ${themeStyles.colors.lightGreys[1].main};
      color: transparent;

      &:after {
        content: '';
        position: absolute;
        background-image: url('${Checkmark}');
        background-size: ${checkmarkSize} ${checkmarkSize};
        width: ${checkmarkSize};
        height: ${checkmarkSize};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}

  ${(props) =>
    props.isActive &&
    css`
      background-color: ${themeStyles.colors.white.main};
      border-color: ${themeStyles.colors.accentColors.secondary.main};
    `}

  &:before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    width: ${gapSize};
    height: 1.5px;
    top: 50%;
    left: 110%;
    transform: translateY(-50%);
    z-index: -2;
    background-image: repeating-linear-gradient(
      90deg,
      transparent,
      transparent 4px,
      ${themeStyles.colors.lightGreys[1].main} 4px,
      ${themeStyles.colors.lightGreys[1].main} 10px
    );
  }

  &:last-child {
    &:before {
      width: 0;
    }
  }
`

export const StyledBackButtonWrapper = styled.div`
  ${themeMedia.maxTablet`
    display: none;
  `}
`
