import * as Sentry from '@sentry/browser'
import { getSmarticoHash } from '../adapters/user'
import { isiOS } from './mobileAppFunctionality'
import storageService from '../services/storageService'
import { handlePlatformAction } from './handlePlatformAction'

export const smarticoLogout = () => {
  window._smartico_user_hash = null
  window._smartico_user_id = null
  window._smartico_language = null
}

export const smarticoLogin = async () => {
  try {
    const user = storageService.getUser()
    const smarticoHashId = await getSmarticoHash()
    window._smartico_user_hash = smarticoHashId.data.data
    window._smartico_user_id = user.id
    window._smartico_language = user.user_language.urlCode

    handlePlatformAction(
      () => window._smartico.setLoginEntry('APP'),
      () => window._smartico.setLoginEntry('MOBILE'),
      () => window._smartico.setLoginEntry('DESKTOP')
    )

    setPushNotificationToken()
  } catch (error) {
    Sentry.captureException(`[Smartico]: Login error: ${error}`)
  }
}

export const setPushNotificationToken = (e) => {
  if (!window.fcm_token) window.fcm_token = e?.detail?.token

  const platformTypeId = isiOS() ? 6 : 7
  window._smartico?.registerNativePushToken(
    e?.detail?.token ?? window.fcm_token ?? '',
    platformTypeId,
    'com.kto.app'
  )
}
