import styled, { css } from 'styled-components'
import { themeMedia, themeStyles } from '../../../styles/theme'

export const StyledDesktopNavBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;

  height: 100%;
  width: 100%;

  ${themeMedia.maxNavbarBreakpointTabletDesktop`
    .logo {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `}

  ${({ isLoggedIn }) =>
    !isLoggedIn &&
    themeMedia.maxNavbarBreakpointMobileTablet`
    flex-direction: column;
    align-items: start;
    padding: 10px 0px;

    .logo {
      top: 0.7em;
    }
  `}
`

export const StyledLeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: row wrap;
  gap: 0.75rem;

  .notification img {
    margin: 0;
  }

  ${themeMedia.navbarBreakpointTabletDesktop`
    gap: 1.25rem;

    .burger-menu {
      display: none;
    }
  `}

  ${themeMedia.navbarBreakpointSmallDesktop`
    .notification {
      display: none;
    }
  `}
`

export const StyledDesktopLink = styled.div`
  ${themeMedia.maxNavbarBreakpointTabletDesktop`
    display: none;
  `}
  ${themeMedia.navbarBreakpointSmallDesktop`
    &:has(.show-on-small-desktop) {
      display: block;
    }
  `}
`

export const StyledRightSide = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  gap: 0.55em;

  > *,
  .cashback,
  .notification,
  .contact-us {
    margin-right: 0px;
  }

  ${({ isLoggedIn }) =>
    isLoggedIn &&
    themeMedia.navbarBreakpointTurnCashbackIntoIcon`
    .contact-us {
      padding: 0;
      font-size: 0;
      width: 36px;
      background-size: 24px;
      background-position: center;
      background-color: ${themeStyles.colors.dominantGrey.main};
    }
  `}

  ${themeMedia.maxNavbarBreakpointTurnCashbackIntoIcon`
    > *,
    .cashback,
    .notification, 
    .contact-us {
      margin-right: 0px;
    }
  `}

  ${themeMedia.maxNavbarBreakpointSmallDesktop`
    .cashback, .notification, .nav-link-row, .contact-us {
      display: none;
    }
  `}

  ${({ isLoggedIn, hideMobileCTA }) =>
    !isLoggedIn &&
    themeMedia.maxNavbarBreakpointMobileTablet`
      padding: 10px 0px;
      gap: 1em;
      width: 100%;

      .cta {
        flex: 1;
        margin: 0 !important;
      }

      ${hideMobileCTA && 'display: none;'}
    `}

  ${({ isLoggedIn }) => themeMedia.maxNavbarBreakpointTabletDesktop`
    > div:has(.refer-friend) {
      display: none;
    }
    ${
      !isLoggedIn &&
      css`
        .cashback,
        .contact-us {
          display: none;
        }
      `
    }
  `}
`
