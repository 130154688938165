import { useStaticQuery, graphql } from 'gatsby'

export const useMenuApiData = (slug) => {
  const data = graphql`
    {
      menuApi {
        values {
          lang
          menuData {
            name
            type
            text
            className
            position
            section
            show_icon
            image {
              alt
              url
              width
              height
            }
            link_logged_in {
              url
            }
            link {
              url
              title
              target
            }
          }
        }
      }
    }
  `
  const response = useStaticQuery(data)
  const {
    menuApi: { values },
  } = response

  const menuData = values?.[0]?.menuData || null

  if (menuData?.length === 0) return null

  const cashbackCta = menuData.some((m) => m.className === 'cta cashback') ?
    menuData.filter((m) => m.className === 'cta cashback')[0] :
    null

  let isVaultPage = false
  const urlPaths = cashbackCta ? cashbackCta.link.url.split('/') : []
  urlPaths.forEach((path) => {
    if (!slug) return

    if (path === slug) {
      isVaultPage = true
    }
  })

  const logo = menuData?.filter((m) => m.className === 'logo')?.[0] || null

  return { cashbackCta, menuData, isVaultPage, logo }
}
