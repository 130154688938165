import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { captureException } from '@sentry/browser'
import { FieldNationalId } from '../../../../molecules/fieldNationalId'
import { Field } from '../../../../molecules/field'
import { FieldPassword } from '../../../../molecules/fieldPassword'
import { Button } from '../../../../atoms/button'
import { Text } from '../../../../atoms/text'
import { passwordRegex } from '../../../../../utils/passwordValidation'
import { emailRegex } from '../../../../../utils/emailValidation'
import { doCpfCheck, doEmailCheck } from '../../../../../adapters/auth'
import { StyledFirstSection } from '../../styles'
import { Link } from 'gatsby'
import useSlugs from '../../../../../hooks/useSlugs'
import { useModalContext } from '../../../../../context/modalProvider'
import { formSubmitIsDisabled } from '../../../../../utils/formUtils'
import useDeviceDetect from '../../../../../hooks/useDeviceDetect'
import { useTranslation } from '../../../../../context/translationProvider'
import { removeCpfMask } from '../../../../../helpers/mask'
import { useTurnstile } from '../../../../../hooks/useTurnstile'
import { brandConfig } from '../../../../../config/brandConfig'
import { useReCaptcha } from '../../../../../hooks/useReCaptcha'
import { useDebounce } from '../../../../../hooks/useDebounce'
import { delay } from '../../../../../utils/delay'

const FirstStep = (props) => {
  const {
    formProperties,
    onCompleteStep,
    country,
    setCafData,
    checkNationalId,
    onInputFocus,
    onInputBlur,
    prepopulatedInputs,
    registerTime,
    setToken,
  } = props
  const [loginSlug] = useSlugs(['login'])
  const [loading, setLoading] = useState(false)
  const { close } = useModalContext()
  const { isMobile } = useDeviceDetect()
  const { translate } = useTranslation()
  const [stepTimer] = useState(new Date())
  const [isCaptchaPassed, setIsCaptchaPassed] = useState(false)
  const { initializeTurnstile, captchaTurnstileOn } = useTurnstile()

  const {
    register,
    getValues,
    setError,
    setValue,
    watch,
    formState: { errors },
  } = formProperties

  const onCaptchaVerified = useCallback((data) => {
    setToken(data)
    setIsCaptchaPassed(true)
  }, [])

  useEffect(() => {
    watch(['national_id', 'email', 'password'])
  }, [])

  initializeTurnstile(onCaptchaVerified)

  // Function to handle the simulated click
  const handleClick = (x, y) => {
    const element = document.elementFromPoint(x, y)
    // Check if the clicked position is really a button
    if (element && element.tagName === 'BUTTON') {
      element.click()
    }
  }

  // Function to capture click events and their positions
  const captureClick = (event) => {
    const { clientX, clientY } = event
    // Call handleClick if not a button to avoid duplicated cliks
    if (event.target.tagName !== 'BUTTON') {
      handleClick(clientX, clientY)
    }
  }

  const setCaptchaToken = ({ recaptchaToken }) => {
    window.captchaToken = recaptchaToken
  }

  const newGetCaptchaToken = useReCaptcha(setCaptchaToken, 'CPF_CHECK')
  const debouncedGetCaptchaToken = useDebounce(newGetCaptchaToken, 500)

  useEffect(() => {
    const init = async () => {
      if (prepopulatedInputs?.['national_id']) {
        // Set the prepopulated national_id value and check if it's a valid CPF
        setValue('national_id', prepopulatedInputs['national_id'])
        await checkCaf(removeCpfMask(prepopulatedInputs['national_id']))
        document.querySelector('#email')?.focus()
      }
    }
    init()
    // Add the click event listener to avoid double clicking
    window.addEventListener('click', captureClick)
    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('click', captureClick)
  }, [])

  const checkEmail = async () => {
    const emailResponse = await doEmailCheck(getValues('email'))
    if (!emailResponse.ok) {
      setError('email', {
        message: translate(
          'register.error.the_username_already_taken_please_select_another'
        ),
      })
      return false
    }
    return true
  }

  const checkCaf = async (nationalId) => {
    debouncedGetCaptchaToken()

    while (!window.captchaToken) {
      await delay(100)
    }

    const localNationalId = nationalId
      ? nationalId
      : getValues('national_id').replaceAll('-', '').replaceAll('.', '')
    // Check if the CPF is valid with the CAF API

    const cafResponse = await doCpfCheck({
      cpf: localNationalId,
      token: window.captchaToken,
    })
    window.captchaToken = null

    if (!cafResponse.ok && !!cafResponse.data) {
      let message = translate('error.cpfInUse')
      switch (cafResponse.data?.status) {
        case 'INVALID':
          message = translate('error.cpf.invalid')
          break
        case 'NOT_PROCESSED':
          message = translate('error.cpf.not_processed')
          break
        case 'DUPLICATED':
          message = translate('error.cpf.duplicated')
          break
      }
      setError('national_id', { message })
      captureException('CPF Check failed with nationalId: ' + localNationalId)
      return false
    }

    if (cafResponse.data?.afId) {
      setCafData(cafResponse.data)
      setValue('afId', cafResponse.data.afId)
      setValue('name', '')
      setValue('dob', '')
      setValue('day', '')
      setValue('month', '')
      setValue('year', '')
    } else {
      captureException(
        'CPF Check returned no data for nationalId: ' + nationalId
      )
      setCafData(null)
      setValue('afId', null)
    }
    return true
  }

  const triggerStepTimer = () => {
    const actualTime = new Date()

    registerTime(
      'first_registration_step',
      actualTime.getTime() - stepTimer.getTime()
    )
  }

  const aditionalChecks = async () => {
    setLoading(true)
    const isEmailGood = await checkEmail()
    if (isEmailGood) {
      if (!checkNationalId) {
        setLoading(false)

        triggerStepTimer()
        return true
      }
      const isCpfGood = await checkCaf()
      setLoading(false)

      if (isCpfGood) triggerStepTimer()

      return isCpfGood
    }
    setLoading(false)
    return false
  }

  const onLogin = () => {
    if (location.pathname.includes(`/${loginSlug}/`)) close()
  }

  const completeFirstStep = () => {
    if (!captchaTurnstileOn || isCaptchaPassed) {
      onCompleteStep(['email', 'password', 'national_id'], aditionalChecks)
    }
  }

  return (
    <StyledFirstSection>
      <FieldNationalId
        formProperties={formProperties}
        country={country}
        showFieldOnlyOn={['BR']}
        description={checkNationalId ? translate('register.enterCPF') : ''}
        onFocus={onInputFocus}
        onBlur={() => onInputBlur('national_id_input_timer')}
      />

      <Field
        label={translate('userProfile.email')}
        maxLength="100"
        type="email"
        autoCompleteOff
        showErrorMsg
        errors={errors}
        onFocus={onInputFocus}
        onBlur={() => onInputBlur('email_input_timer')}
        formProperties={register('email', {
          required: true,
          pattern: {
            value: emailRegex,
            message: translate('register.error.incorrectEmailFormat'),
          },
        })}
      />

      <FieldPassword
        errors={errors}
        autoCompleteOff
        description={translate('common.passwordHint')}
        showTipsOnFocus
        scrollOnFocus={isMobile}
        onDone={completeFirstStep}
        onFocus={onInputFocus}
        onBlur={() => onInputBlur('password_input_timer')}
        defaultValue={getValues('password')}
        formProperties={register('password', {
          required: true,
          length: {
            value: 8,
            message: translate('common.passwordHint'),
          },
          pattern: {
            value: passwordRegex,
            message: translate('common.passwordHint'),
          },
        })}
      />
      <div className="checkbox">
        <div id="turnstilCaptcha"></div>
      </div>
      <Button
        id="nextBtn1"
        expand
        loading={loading}
        type="button"
        style={{
          fontSize: brandConfig.fonts.text.button.size.desktop,
        }}
        disabled={
          formSubmitIsDisabled(
            country?.code == 'BR'
              ? ['email', 'password', 'national_id']
              : ['email', 'password'],
            getValues,
            errors
          ) ||
          (!isCaptchaPassed && captchaTurnstileOn)
        }
        onClick={completeFirstStep}
      >
        {translate('register.next')}
      </Button>

      <Text textAlign="center">
        {translate('register.alreadyHaveAccount')}{' '}
        <Link to={`/${loginSlug}`} onClick={onLogin}>
          {translate('register.login')}
        </Link>
      </Text>
    </StyledFirstSection>
  )
}

FirstStep.propTypes = {
  formProperties: PropTypes.shape({
    register: PropTypes.func,
    getValues: PropTypes.func,
    setError: PropTypes.func,
    setValue: PropTypes.func,
    watch: PropTypes.func,
    formState: PropTypes.shape({ errors: PropTypes.object }),
  }),
  checkNationalId: PropTypes.bool,
  onCompleteStep: PropTypes.func,
  setCafData: PropTypes.func,
  country: PropTypes.object,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  prepopulatedInputs: PropTypes.object,
  registerTime: PropTypes.func,
  setToken: PropTypes.func,
}

export { FirstStep }
