import React from 'react'
import PropTypes from 'prop-types'
import { BackButton } from '../backButton'
import { If } from '../if'
import {
  StyledProgressBarStep,
  StyledOuterWrapper,
  StyledStepsWrapper,
  StyledBackButtonWrapper,
} from './styles'

const ProgressBar = (props) => {
  const { currentStep, numberOfSteps, goBack } = props

  if (currentStep >= numberOfSteps) return <></>
  return (
    <StyledOuterWrapper>
      <StyledBackButtonWrapper>
        <If
          condition={currentStep > 0}
          render={() => (
            <BackButton whiteBackground onClick={goBack} left="0" />
          )}
        />
      </StyledBackButtonWrapper>
      <StyledStepsWrapper>
        {[...Array(numberOfSteps)].map((_, i) => (
          <StyledProgressBarStep
            key={`progress-step-${i}`}
            isDone={currentStep > i}
            isActive={currentStep == i}
          >
            {i + 1}
          </StyledProgressBarStep>
        ))}
      </StyledStepsWrapper>
    </StyledOuterWrapper>
  )
}

ProgressBar.propTypes = {
  currentStep: PropTypes.number,
  numberOfSteps: PropTypes.number,
  goBack: PropTypes.func,
}

export { ProgressBar }
