import { apiGET, apiPOST, apiPUT } from '../utils/adapterFunctions'

export const doLogin = async (username, password, token) => {
  return await apiPOST('/auth/login', { username, password, token })
}

export const doLogout = async () => {
  return await apiPOST('/auth/logout', null, { hasNoData: true })
}

export const getWallet = async () => {
  return await apiGET('/wallet')
}

export const doForgotPassword = async (email, returnUrl, token) => {
  return await apiPOST(
    '/auth/password/forgot/',
    {
      email,
      password_reset_url: returnUrl,
      token,
    },
    { hasNoData: true }
  )
}

export const doActivate = async (token) => {
  return await apiPOST(`/auth/activate/${token}/`, null, {
    hasNestedData: false,
  })
}

export const doVerifyEmail = async (token) => {
  return await apiPUT(`/auth/email/verify/${token}/`, null)
}

export const doResetPassword = async (model) => {
  return apiPOST('/auth/password/reset/', model, { hasNoData: true })
}

export const doCpfCheck = async ({ cpf, token = null }) => {
  return apiPOST(
    '/registration/cpf/check',
    {
      cpf,
      token,
    },
    {
      hasNestedData: false,
      hasValid: true,
    }
  )
}

export const doEmailCheck = async (email) => {
  return apiPOST(
    '/registration/email/check',
    { email },
    { hasNestedData: false, hasValid: true }
  )
}

export const doRegistration = async (model, isEmailVerificationEnabled) => {
  const endpointVersion = isEmailVerificationEnabled ? 1 : 2
  return await apiPOST(`/registration/v${endpointVersion}`, model)
}

export const doRefreshToken = async (refreshToken, accessToken) => {
  return await apiPOST('/auth/refresh-token', {
    refresh_token: refreshToken,
    auth_token: accessToken,
  })
}

export const getCurrentUser = async () => {
  return await apiGET('/user')
}

// export const getUserActiveStatus = async () => {
//   try {
//     await coreApi.get('/session')
//   } catch (err) {
//     console.log(err)
//   }
// }

export const countryCheckTranslations = async () => {
  return await apiGET('/country/check')
}

export const doBirthdayCheck = async (cpf, dateOfBirth) => {
  return await apiPOST('/dob-check', { cpf, dateOfBirth }, { hasValid: true })
}
