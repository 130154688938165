import React, { useState, useEffect } from 'react'
import {
  StyledCookieMessageWrapper,
  StyledNoticeText,
  CTAButtonsWrapper,
  StyledButton,
} from './styles'
import { If } from '../../atoms/if'
import { Modal } from '../../atoms/modal'
import CookieSettings from '../../../templates/footer/cookieSettings'
import useModal from '../../../hooks/useModal'
import useCookie from '../../../hooks/useCookie'
import { useTranslation } from '../../../context/translationProvider'

const CookiesBanner = () => {
  const { translate } = useTranslation()
  const [cookie, updateCookie] = useCookie('caccept', '')
  const [cookieAccepted, setCookieAccepted] = useState(true)
  const { openModal, closeModal, isOpen } = useModal()

  useEffect(() => {
    setCookieAccepted(Boolean(cookie))
  }, [cookie])

  const handleAcceptCookies = () => {
    updateCookie('on', 365)
    setCookieAccepted(true)
  }

  if (cookieAccepted) {
    return null
  }

  return (
    <>
      <StyledCookieMessageWrapper>
        <StyledNoticeText
          dangerouslySetInnerHTML={{
            __html: translate('common.cookie.disclamer'),
          }}
        />
        <CTAButtonsWrapper>
          <StyledButton bordered onClick={openModal}>
            {translate('common.cookie.preferences')}
          </StyledButton>
          <StyledButton primary onClick={handleAcceptCookies}>
            {translate('common.cookie.button')}
          </StyledButton>
        </CTAButtonsWrapper>
      </StyledCookieMessageWrapper>
      <If
        condition={isOpen}
        render={() => (
          <Modal
            title={translate('common.cookie.settings')}
            isOpen={isOpen}
            onClose={closeModal}
          >
            <CookieSettings
              onClose={closeModal}
              onUpdate={() => setCookieAccepted(true)}
              forceCheck={true}
            />
          </Modal>
        )}
      />
    </>
  )
}

export default CookiesBanner
