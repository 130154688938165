const { addTrailingSlash } = require('./addTrailingSlash')

const locale = process.env.GATSBY_INTL_LOCALE
const env = process.env.GATSBY_ENV
const useLocalePathPrefix = process.env.GATSBY_USE_LOCALE_PATH_PREFIX === 'true'

const getWebsiteUrl = () => {
  const baseUrl = addTrailingSlash(process.env.GATSBY_KTO_URL)

  if (env === 'dev') return baseUrl
  if (useLocalePathPrefix) return `${baseUrl}${locale}/`
  return baseUrl
}

module.exports = { useLocalePathPrefix, getWebsiteUrl }
