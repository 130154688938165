import { useEffect, useState } from 'react'
import { isBrowser } from '../utils/generic'
import storageService from '../services/storageService'

export const useRegistrationSteps = () => {
  const [formStep, setFormStep] = useState(0)

  const goBack = () => {
    setFormStep((current) => {
      if (current <= 0) return 0
      return current - 1
    })
  }

  useEffect(() => {
    if (!isBrowser()) return
    storageService.setValue('lastFormStep', formStep)

    window.scrollTo(0, 0)
  }, [formStep])

  return { formStep, setFormStep, goBack }
}
