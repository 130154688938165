import { css } from 'styled-components'
import { brandConfig } from '../../config/brandConfig'

export const themeStyles = brandConfig

export const themeSizes = {
  /** Size: 1980px  */ uhd: 1980,
  /** Size: 1366px  */ widescreen: 1366,
  /** Size: 1024    */ desktop: 1024,
  /** Size: 768px   */ tablet: 768,
  /** Size: 480px   */ mobile: 480,
  /** Size: 1700px  */ navbarBreakpointTurnCashbackIntoIcon: 1700,
  /** Size: 1600px  */ navbarBreakpointTabletDesktop: 1600,
  /** Size: 1280px  */ navbarBreakpointSmallDesktop: 1280,
  /** Size: 600px  */ navbarBreakpointMobileTablet: 600,
}

export const themeMedia = Object.keys(themeSizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${themeSizes[label]}px) {
      ${css(...args)};
    }
  `

  acc['max' + label.charAt(0).toUpperCase() + label.slice(1)] = (
    ...args
  ) => css`
    @media (max-width: ${themeSizes[label] - 1}px) {
      ${css(...args)};
    }
  `

  return acc
}, {})
