import { removeNationalIdMask, removePhoneMask } from '../../helpers/mask'
import { cookiePrefix, getCookie } from '../cookies'
import allLocales from '../../i18n/locales'
import { triggerFormFields } from '../formUtils'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { getWebsiteUrl } from '../getWebsiteUrl'

const locale = process.env.GATSBY_INTL_LOCALE

export const useRegisterModel = () => {
  const enablePushNotifications = useFeatureValue(
    'enable_push_notifications_selection'
  )

  const getRegisterModel = async (
    data,
    formProperties,
    languages,
    friendReferrerCode,
    country
  ) => {
    const { trigger, getValues } = formProperties
    const didTriggerWell = await triggerFormFields(trigger, [
      'tandc',
      'address',
      'postcode',
      'city',
      'country',
      'currency',
    ])
    if (!didTriggerWell) return
    const websiteURL = getWebsiteUrl()

    const tandcChecked = getValues('tandc')
    const marketingChecked = getValues('marketing')

    const langCode = allLocales[locale].code
    const language = languages.find((l) => l.code.toLowerCase() === langCode)

    const isOrganicOrBranded = getCookie(`${cookiePrefix}googleOrganic`)
    const affiliateToken = getCookie(`${cookiePrefix}affiliateToken`) || null
    const affiliateTokenCreationDate =
      getCookie(`${cookiePrefix}affiliateTokenCreationDate`) || null
    const PPCToken = getCookie(`${cookiePrefix}PPCToken`) || null
    const caToken = getCookie(`${cookiePrefix}caToken`) || null
    const caTokenCreationDate =
      getCookie(`${cookiePrefix}caTokenCreationDate`) || null

    const getUrlToken = () => {
      if (PPCToken)
        return {
          token: PPCToken,
          type: 'myAffiliates',
        }

      if (!!isOrganicOrBranded && isOrganicOrBranded !== 'false')
        return {
          token: isOrganicOrBranded,
          type: 'SEO',
        }

      if (caToken && affiliateToken) {
        if (caTokenCreationDate > affiliateTokenCreationDate) {
          return {
            token: caToken,
            type: 'cleverAdvertising',
          }
        }

        return {
          token: affiliateToken,
          type: 'myAffiliates',
        }
      }

      if (caToken)
        return {
          token: caToken,
          type: 'cleverAdvertising',
        }

      if (affiliateToken)
        return {
          token: affiliateToken,
          type: 'myAffiliates',
        }

      return null
    }

    const url_token = getUrlToken()?.token
    const url_token_type = getUrlToken()?.type

    const model = {
      email: data.email.trim().slice(0, 100).toLowerCase(),
      password: data.password,
      languageCode: language?.code,
      address: data.address.slice(0, 128),
      city: data.city.slice(0, 90),
      state: data.state.slice(0, 90),
      countryCode: country.code,
      afId: data.afId,
      currencyCode: data.currency,

      mobilePrefix: data.mobilePrefix,
      mobileNumber: removePhoneMask(data.mobileNumber),
      affiliateMarker: data.couponCode?.slice(0, 50),
      affToken: url_token || null,
      tokenType: url_token_type || null,

      acceptNews: marketingChecked,
      acceptTc: tandcChecked,
      isPushNotificationsAllowed: enablePushNotifications
        ? marketingChecked
        : false,
      activationUrl: `${websiteURL}activate/`,
      token: data?.recaptchaToken,
      referralToken: friendReferrerCode?.replace('/', ''),
      nationalId: data.national_id
        ? removeNationalIdMask(data.national_id)
        : null,
      dateOfBirth: data.dob,
    }

    if (!model.afId) {
      model.fullName = data.name.slice(0, 150)
    }

    return model
  }

  return { getRegisterModel }
}
