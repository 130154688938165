import styled, { css } from 'styled-components'
import { themeStyles, themeMedia } from '../../../styles/theme'
import { brandConfig } from '../../../config/brandConfig'

export const MODAL_HEADER_HEIGHT = '2.8em'

export const StyledModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  background-color: ${themeStyles.colors.black.main};
  color: ${themeStyles.colors.white.main};
  padding: 0.5em;
  height: ${MODAL_HEADER_HEIGHT};
  box-sizing: border-box;
  font-size: ${brandConfig.fonts.text.paragraph.size.mobile};

  .item {
    padding: 0.5em;
    width: 14px;
    height: 14px;
  }

  img.item {
    cursor: pointer;
  }

  ${(props) => themeMedia[props.desktopLayoutBreakpoint]`
    font-size: 1.5em;
    font-weight: 600;
    color: ${themeStyles.colors.black.main};
    background-color: ${themeStyles.colors.white.main};
    line-height: initial;
    padding: 0;

    ${({ isPopup }) =>
      isPopup
        ? css`
            > img:first-child {
              opacity: 0;
              cursor: default;
            }
          `
        : css`
            > img {
              display: none;
            }
          `}

    ${({ theme }) =>
      theme === 'simple' &&
      css`
        position: initial;
        span {
          display: none;
        }

        img.item {
          position: absolute;
          top: 8px;
          right: 8px;

          width: 16px;
          height: 16px;
        }
      `}
   
  `};
`

export const StyledOuterModalHeaderWrapper = styled.div`
  position: relative;
  height: ${MODAL_HEADER_HEIGHT};

  ${({ stickHeaderToTop }) =>
    stickHeaderToTop &&
    css`
      ${StyledModalHeaderWrapper} {
        position: fixed;
        width: 100%;
        z-index: 100;
        top: 0;
        left: 0;
      }
    `}

  ${({ theme }) =>
    theme === 'simple' &&
    css`
      position: initial;
    `}
`
